// 3rd parties
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Form, Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// BL
import {
	getCities,
	getCountries,
} from '../../../BusinessLogic/redux/reducers/staticData';
import {
	updateEditingCall,
	updateFormTemporaryCallSlug,
	cleanFormTemporaryCallSlug,
	updateMainImageUploadedBeforeSubmit,
	updateOrganizerImageUploadedBeforeSubmit,
} from '../../../BusinessLogic/redux/reducers/call';

import { dateISOFormat } from '../../../BusinessLogic/helpers/dateFormats';
import { singleCallFormValidationSchema } from '../../../BusinessLogic/data/singleCallData';
import {
	useGetCallForm,
	useGetSingleCall,
	usePutCall,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';

// UI
import ListItem from '@material-ui/core/ListItem';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import FieldTextFormik from '../../../UI/molecules/Field/FieldTextFormik';
import FieldTextAreaFormik from '../../../UI/molecules/Field/FieldTextAreaFormik';
import ListItemText from '../../../UI/atoms/Inputs/ListItemText';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import TextContentPrincipal from '../../../UI/atoms/Text/Content/TextContentPrincipal';
import UnderlineHr from '../../../UI/atoms/UnderLine/UnderlineHr';
import CardLink from '../../../UI/molecules/Card/CardLink';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import FieldDateFormik from '../../../UI/molecules/Field/FieldDateFormik';
import RadioSelectFormik from '../../../UI/molecules/Field/RadioSelectFormik';
import DropZoneModal from '../../../UI/molecules/DropZone/DropZoneModal';
import FieldURLFormik from '../../../UI/molecules/Field/FieldURLFormik';
import LoadingText from '../../../UI/molecules/Loading/LoadingText';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

/**
 * @description render the form for the creation of a call from organizations.
 * @returns
 */

const SingleCallForm = () => {
	const { t } = useTranslation();
	const { slug } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { organization_user_id } = localStorage;
	const { countries } = useSelector((state) => state.staticData);
	const { editingCall, formTemporaryCallSlug } = useSelector(
		(state) => state.call
	);

	const { singleCall } = useGetSingleCall(slug);
	const { singleCallForm } = useGetCallForm(singleCall?.form_id);
	const putCallMutation = usePutCall();

	const [callCities, setCallCities] = useState([]);
	const [callOrganizationCities, setCallOrganizationCities] = useState([]);

	const [dropZoneMainFiles, setDropZoneMainFiles] = useState([]);
	const [showDropzoneMainImageModal, setShowDropzoneMainImageModal] =
		useState(false);

	const [dropZoneOrganizerFiles, setDropZoneOrganizerFiles] = useState([]);
	const [showDropzoneOrganizerImageModal, setShowDropzoneOrganizerImageModal] =
		useState(false);

	const [isSlugAlreadyUsed, setIsSlugAlreadyUsed] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (Array.isArray(dropZoneMainFiles) && dropZoneMainFiles.length > 0) {
			dispatch(updateMainImageUploadedBeforeSubmit(dropZoneMainFiles));
		}
	}, [dropZoneMainFiles, dispatch]);

	useEffect(() => {
		if (
			Array.isArray(dropZoneOrganizerFiles) &&
			dropZoneOrganizerFiles.length > 0
		) {
			dispatch(
				updateOrganizerImageUploadedBeforeSubmit(dropZoneOrganizerFiles)
			);
		}
	}, [dropZoneOrganizerFiles, dispatch]);

	useEffect(() => {
		dispatch(
			updateEditingCall({
				accepts_tcs: singleCall?.accepts_tcs,
				benefits: [],
				call_id: String(singleCall?.id),
				category: singleCall?.category ?? '',
				city: singleCall?.city,
				closes_at: dateISOFormat(singleCall?.closes_at),
				copy_page: singleCall?.copy_page ?? '',
				country_id: singleCall?.country_id ?? 47,
				form_id: singleCall?.form_id,
				has_benefit: singleCall?.has_benefit,
				has_cv: singleCall?.has_cv,
				has_form: singleCall?.has_form,
				has_judge: singleCall?.has_judge,
				has_portfolio: singleCall?.has_portfolio,
				header: singleCall?.header ?? '',
				judge_is_public: singleCall?.judge_is_public,
				judge: singleCall?.judge,
				main_picture_url: singleCall?.main_picture_url,
				name: singleCall?.name ?? '',
				opens_at: dateISOFormat(singleCall?.opens_at),
				organization_user_id,
				requirements: singleCall?.requirements ?? '',
				results_at: dateISOFormat(singleCall?.results_at),
				sub_header: singleCall?.sub_header ?? '',
				status: singleCall?.status,
				technique: singleCall?.technique ?? '',
				main_image_uploaded_before_submit:
					editingCall?.main_image_uploaded_before_submit ?? [],
				organizer_image_uploaded_before_submit:
					editingCall?.organizer_image_uploaded_before_submit ?? [],
				type: singleCall?.type,
				call_organizer: [
					{
						name: singleCall?.call_organization_name,
						description: singleCall?.call_organization_description,
						email: singleCall?.call_organization_email,
						phone: singleCall?.call_organization_phone,
						website: singleCall?.call_organization_website,
						address: singleCall?.call_organization_address,
						country_id: singleCall?.call_organization_country_id,
						city: singleCall?.call_organization_city,
						postal_code: singleCall?.call_organization_postcode,
						image: singleCall?.call_organization_image,
						id: singleCall?.call_organization_id,
					},
				],
			})
		);
	}, [singleCall, organization_user_id, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!countries) {
			dispatch(getCountries());
		}
		if (editingCall.country_id) {
			dispatch(getCities(editingCall.country_id)).then((res) => {
				setCallCities(res.payload?.cities);
			});
		}
		if (editingCall.call_organizer[0].country_id) {
			dispatch(getCities(editingCall.call_organizer[0].country_id)).then(
				(res) => {
					setCallOrganizationCities(res.payload?.cities);
				}
			);
		}
	}, [
		dispatch,
		editingCall.country_id,
		editingCall.call_organizer.country_id,
		editingCall.call_organizer,
		countries,
	]);

	const handleCountryChange = (e, field, setCities, formProps) => {
		const countryId = e.target.value;
		formProps.setFieldValue(field, countryId);
		dispatch(getCities(countryId)).then((res) => {
			setCities(res.payload?.cities);
		});
	};

	const checkIfContainsAValidURL = (slug) => {
		return /^[a-z0-9]+(?:[-,_][a-z0-9]+)*$/.test(slug);
	};

	const handleFormTemporaryCallSlug = (value) => {
		if (checkIfContainsAValidURL(value)) {
			dispatch(updateFormTemporaryCallSlug(value));
		}
	};

	const handleOnSubmit = async (values, { setErrors }) => {
		if (
			String(organization_user_id) !== String(singleCall?.organization_user_id)
		) {
			return;
		}
		const mainFile = editingCall.main_image_uploaded_before_submit[0]?.file;
		const organizerFile =
			editingCall.organizer_image_uploaded_before_submit[0]?.file;
		let valuesToSend = {
			...values,
		};
		valuesToSend.route_slug = formTemporaryCallSlug ?? slug;
		if (mainFile) {
			valuesToSend['large_file'] = mainFile;
		}
		if (organizerFile) {
			valuesToSend['call_organizer_file'] = organizerFile;
		}

		if (!valuesToSend.call_organizer[0].name.trim()) {
			setErrors({
				call_organizer: [
					{
						name: 'CampoRequerido',
					},
				],
			});
			return;
		}

		setIsLoading(true);
		const res = await putCallMutation
			.mutateAsync(valuesToSend)
			.finally(() => setIsLoading(false));

		if (res.code === 3) {
			setIsSlugAlreadyUsed(true);
		} else {
			dispatch(cleanFormTemporaryCallSlug());
			dispatch(updateMainImageUploadedBeforeSubmit([]));
			dispatch(updateOrganizerImageUploadedBeforeSubmit([]));
			navigate(`/convocatorias`);
		}
	};

	const backAction = () => {
		dispatch(updateMainImageUploadedBeforeSubmit([]));
		dispatch(updateOrganizerImageUploadedBeforeSubmit([]));
	};

	const handleUpdateCallFromForm = (values) => {
		dispatch(updateEditingCall(values));
		putCallMutation.mutateAsync(values);
	};

	return (
		<TemplateWithNavBar>
			<NavbarSecondaryNew
				title={singleCall?.name ? 'Editar convocatoria' : 'Nueva convocatoria'}
				textColor='text-purple-50 w-max'
				color='bg-purple-50'
				showDots={false}
				redirect={`/convocatorias`}
				textBack={`${t('RegresarConvocatoria')}`}
				textBackDesign='pl-3 text-purple-50'
				arrowLeftColor='purple'
				backAction={backAction}
			/>
			<Formik
				enableReinitialize
				initialValues={{
					...editingCall,
					route_slug:
						formTemporaryCallSlug ?? singleCall?.route_slug.toLowerCase(),
				}}
				validationSchema={singleCallFormValidationSchema}
				onSubmit={(values, formikHelpers) => {
					if (
						String(organization_user_id) !==
						String(singleCall?.organization_user_id)
					)
						return;
					handleOnSubmit(values, formikHelpers);
				}}
			>
				{(formProps) => {
					const mainImageSrc =
						Array.isArray(editingCall.main_image_uploaded_before_submit) &&
						editingCall.main_image_uploaded_before_submit.length > 0
							? URL.createObjectURL(
									editingCall.main_image_uploaded_before_submit[0]?.file
							  )
							: formProps.values.main_picture_url;

					const organizerImageSrc =
						Array.isArray(editingCall.organizer_image_uploaded_before_submit) &&
						editingCall.organizer_image_uploaded_before_submit.length > 0
							? URL.createObjectURL(
									editingCall.organizer_image_uploaded_before_submit[0]?.file
							  )
							: formProps.values.call_organizer[0].image;

					return (
						<Form>
							<div className='mb-10 flex w-full flex-col md:flex-row md:gap-8'>
								<div className='mx-auto flex w-11/12 flex-col gap-4 md:flex-row lg:w-4/5 lg:gap-12 '>
									<div className='flex w-full flex-col items-center gap-4 py-8'>
										<LazyLoadImage
											alt={''}
											src={mainImageSrc}
											effect='blur'
											wrapperClassName='px-4'
											className='object-contain'
											delayTime='0'
											threshold='800'
										/>
										<ButtonFabExtendedPrimary
											text={'Subir portada'}
											action={() => {
												setShowDropzoneMainImageModal(true);
												handleUpdateCallFromForm(formProps.values);
											}}
											typeButton={'button'}
										/>

										<TextContentSecond
											design={'py-2 text-red-400'}
											text={t('NotaTamañoSoportado')}
										/>
									</div>
								</div>
								<div className='flex w-full flex-col md:pr-4'>
									<div className='flex flex-col gap-1 py-4'>
										<TextTitleSub
											title={'Descripción'}
											textColor={'text-purple-50'}
										/>
										<UnderlineHr colour={'purple'} />
									</div>
									<FieldTextFormik
										label={t('Título')}
										dbName='name'
										type='Text'
										designInput={'!bg-middle_grey-50 h-7'}
										errors={formProps.errors?.name}
										errorMessageDesign={'text-red-400 ml-auto text-sm'}
									/>
									<div>
										<FieldURLFormik
											label={t('URL')}
											dbName='route_slug'
											designInput={'!bg-middle_grey-50 h-7'}
											errors={
												isSlugAlreadyUsed
													? 'Esta URL está en uso. Por favor, ingresa una nueva.'
													: formProps.errors?.route_slug
											}
											errorMessageDesign={'text-red-400 ml-auto text-sm'}
											handleOnBlur={handleFormTemporaryCallSlug}
											handleUpdateEditingCall={() =>
												dispatch(updateEditingCall(formProps.values))
											}
											formProps={formProps}
										/>
									</div>
									<section>
										<ListItem
											style={{
												paddingLeft: '0px',
												paddingRight: '0px',
												justifyContent: 'space-between',
											}}
										>
											<ListItemText
												primary={t('País de origen')}
												design={'text-sm'}
											/>
											<Field
												name='country_id'
												type='Text'
												as='select'
												onChange={(e) =>
													handleCountryChange(
														e,
														'country_id',
														setCallCities,
														formProps
													)
												}
												designInput={'h-7 bg-middle_grey-50'}
												className='body-Text2 h-7 w-40 rounded border-none bg-middle_grey-50 md:w-56'
											>
												<option value=''></option>
												{countries?.map((country) => (
													<option value={country.value}>{country.label}</option>
												))}
											</Field>
										</ListItem>
										{formProps.errors?.country_id && (
											<div className={`ml-auto w-40 text-red-400 md:w-56`}>
												{t(formProps.errors?.country_id)}
											</div>
										)}

										<ListItem
											style={{
												paddingLeft: '0px',
												paddingRight: '0px',
												justifyContent: 'space-between',
											}}
										>
											<ListItemText design={'text-sm'} primary={t('Ciudad')} />
											<Field
												name='city'
												type='Text'
												as='select'
												className='body-Text2 h-7 w-40 rounded border-none bg-middle_grey-50 md:w-56'
											>
												<option value=''></option>
												{callCities?.length > 1 &&
													callCities?.map((city) => (
														<option value={city.label}>{city.label}</option>
													))}
											</Field>
										</ListItem>
									</section>

									<section className='justify-initial'>
										<ListItem
											style={{
												paddingLeft: '0px',
												paddingRight: '0px',
												justifyContent: 'space-between',
												alignItems: 'start',
											}}
										>
											<ListItemText
												primary={t('Categoría')}
												design='body-Text2 w-36'
												style={{ fontWeight: 400 }}
											/>
											<div className='flex w-40 flex-col items-start gap-2 md:w-56'>
												<RadioSelectFormik
													dbName='category'
													options={[
														{ label: 'Exposición', value: 'Exposición' },
														{
															label: 'Residencia Artística',
															value: 'Residencia Artística',
														},
														{
															label: 'Revisión de Portafolios',
															value: 'Revisión de Portafolios',
														},
													]}
													className='flex flex-row'
													labelListDesign='flex flex-col gap-1'
													inputType={'checkbox'}
												/>
											</div>
										</ListItem>
									</section>

									<section className='justify-initial'>
										<ListItem
											style={{
												paddingLeft: '0px',
												paddingRight: '0px',
												justifyContent: 'space-between',
												alignItems: 'start',
											}}
										>
											<ListItemText
												primary={t('Técnica')}
												design='body-Text2 w-36'
												style={{ fontWeight: 400 }}
											/>
											<div className='flex w-40 flex-col gap-2 md:w-56'>
												<RadioSelectFormik
													dbName='technique'
													options={[
														{ label: 'Pintura', value: 'Pintura' },
														{ label: 'Escultura', value: 'Escultura' },
														{ label: 'Dibujo', value: 'Dibujo' },
														{ label: 'Performance', value: 'Performance' },
														{ label: 'Videoarte', value: 'Videoarte' },
														{ label: 'Otra', value: 'Otra' },
													]}
													className='flex flex-row'
													labelListDesign='flex flex-col gap-1'
													inputType={'checkbox'}
												/>
											</div>
										</ListItem>
									</section>

									<section>
										<div className='mt-6 flex items-center gap-1 py-2'>
											<TextTitleSub
												title={'Descripción corta'}
												textColor={'text-purple-50'}
											/>
										</div>
										<UnderlineHr colour={'purple'} />
										<TextContentSecond
											design={'my-3'}
											text={
												'Esta descripción se va a ver en la sección donde están todas las convocatorias y debe ser un resumen de la convocatoria. '
											}
										/>
										<FieldTextAreaFormik
											label='description'
											dbName='sub_header'
											designInput={'bg-middle_grey-50'}
											errors={formProps.errors?.sub_header}
											errorMessageDesign={'text-red-400 ml-auto text-sm'}
										/>
									</section>

									<section>
										<div className='mt-2 flex flex-col gap-1 py-2'>
											<TextTitleSub
												title={'Descripción detallada'}
												textColor={'text-purple-50'}
											/>
											<UnderlineHr colour={'purple'} />
										</div>
										<TextContentSecond
											design={'my-2'}
											text={
												'Esta descripción se va a ver en los detalles de la convocatoria.'
											}
										/>
										<FieldTextAreaFormik
											label='description'
											dbName='header'
											designInput={'bg-middle_grey-50'}
											errors={formProps.errors?.header}
											errorMessageDesign={'text-red-400 ml-auto text-sm'}
										/>
									</section>

									<section>
										<div className='mt-6 flex items-center gap-1'>
											<TextTitleSub
												title={'Detalles de la convocatoria'}
												textColor={'text-purple-50'}
											/>
										</div>
										<UnderlineHr colour={'purple'} />

										<TextContentPrincipal
											text={'Requisitos de elegibilidad'}
											design={'text-purple-50 py-4'}
											style={{ fontWeight: 400 }}
										/>

										<FieldTextAreaFormik
											label='description'
											dbName='requirements'
											designInput={'bg-middle_grey-50'}
											errors={formProps.errors?.requirements}
											errorMessageDesign={'text-red-400 ml-auto text-sm'}
										/>
									</section>

									<CardLink
										title={'Beneficios'}
										text={
											singleCall?.has_benefit
												? `${
														singleCall?.benefits?.length ?? 0
												  } beneficios agregados`
												: 'Sin beneficios'
										}
										redirect={`/convocatorias/${slug}/beneficios`}
										designContainer={'mt-2 md:py-4'}
										titleDesign={'text-purple-50'}
										redirectAction={() => {
											const values = {
												...formProps.values,
												route_slug: slug,
											};
											handleUpdateCallFromForm(values);
										}}
									/>

									<section>
										<TextContentPrincipal
											text={'Fechas importantes'}
											design={'text-purple-50 my-6'}
											style={{ fontWeight: 600 }}
										/>

										<div className='flex flex-col gap-4'>
											<FieldDateFormik
												label='Inicio'
												dbName='opens_at'
												className='font-bold'
												dateTime='true'
											/>
											<FieldDateFormik
												label='Final'
												dbName='closes_at'
												className='font-bold'
												dateTime='true'
											/>
											<TextContentSecond
												className='w-full'
												design={'text-red-400 text-right text-sm font-bold'}
												text={t(formProps.errors?.closes_at)}
											/>
											<FieldDateFormik
												label='Resultados'
												dbName='results_at'
												className='font-bold'
												dateTime='true'
											/>
										</div>
									</section>

									{/* <CardLink
									title={'Jurados'}
									text={'3 jurados público'}
									redirect={'#'}
									designContainer={'!items-start mt-8 md:py-4'}
									titleDesign={'text-purple-50'}
								/> */}

									<section>
										<div className='mt-8 py-2'>
											<TextTitleSub
												title={'Organizado por'}
												textColor={'text-purple-50'}
											/>
											<UnderlineHr colour={'purple'} />
										</div>

										<div className='flex w-full items-center justify-between py-2'>
											<LazyLoadImage
												alt={''}
												src={organizerImageSrc}
												effect='blur'
												wrapperClassName='w-16 aspect-square rounded-full flex items-center lazyLoadingImages overflow-hidden'
												className='max-h-full object-contain'
												delayTime='0'
												threshold='800'
											/>

											<div className='w-40 md:w-56'>
												<div
													className='w-max cursor-pointer'
													onClick={() => {
														setShowDropzoneOrganizerImageModal(true);
														handleUpdateCallFromForm(formProps.values);
													}}
												>
													<TextContentSecond
														text={'Editar/Subir'}
														design={'text-blue-500'}
														style={{ fontWeight: 400 }}
													/>
													<UnderlineHr colour={'blue'} />
												</div>
											</div>
										</div>

										<FieldTextFormik
											label={t('Nombre')}
											dbName='call_organizer[0].name'
											type='Text'
											designInput={'h-7 !bg-middle_grey-50'}
											errors={
												formProps.errors.call_organizer &&
												formProps.errors.call_organizer[0].name
											}
											errorMessageDesign={'text-red-400 ml-auto text-sm'}
										/>

										<FieldTextFormik
											label={t('Descripción')}
											dbName='call_organizer[0].description'
											type='Text'
											designInput={'h-7 !bg-middle_grey-50'}
											errors={
												formProps.errors.call_organizer &&
												formProps.errors.call_organizer[0].description
											}
											errorMessageDesign={'text-red-400 ml-auto text-sm'}
										/>
										<FieldTextFormik
											label={t('Email')}
											dbName='call_organizer[0].email'
											type='Text'
											designInput={'h-7 !bg-middle_grey-50'}
											errors={
												formProps.errors.call_organizer &&
												formProps.errors.call_organizer[0].email
											}
											errorMessageDesign={'text-red-400 ml-auto text-sm'}
										/>
										<FieldTextFormik
											label={'Teléfono'}
											dbName='call_organizer[0].phone'
											type='Text'
											designInput={'h-7 !bg-middle_grey-50'}
											errors={
												formProps.errors.call_organizer &&
												formProps.errors.call_organizer[0].phone
											}
											errorMessageDesign={'text-red-400 ml-auto text-sm'}
										/>
										<FieldTextFormik
											label={t('Página web')}
											dbName='call_organizer[0].website'
											type='Text'
											designInput={'h-7 !bg-middle_grey-50'}
											errors={
												formProps.errors.call_organizer &&
												formProps.errors.call_organizer[0].website
											}
											errorMessageDesign={'text-red-400 ml-auto text-sm'}
										/>
										<FieldTextFormik
											label={t('Dirección')}
											dbName='call_organizer[0].address'
											type='Text'
											designInput={'h-7 !bg-middle_grey-50'}
											errors={
												formProps.errors.call_organizer &&
												formProps.errors.call_organizer[0].address
											}
											errorMessageDesign={'text-red-400 ml-auto text-sm'}
										/>
										<ListItem
											style={{
												paddingLeft: '0px',
												paddingRight: '0px',
												justifyContent: 'space-between',
											}}
										>
											<ListItemText
												primary={t('País de origen')}
												design={'text-sm'}
											/>
											<Field
												name='call_organizer[0].country_id'
												type='Text'
												as='select'
												onChange={(e) =>
													handleCountryChange(
														e,
														'call_organizer[0].country_id',
														setCallOrganizationCities,
														formProps
													)
												}
												className='body-Text2 h-7 w-40 rounded border-none bg-middle_grey-50 md:w-56'
											>
												<option value=''></option>
												{countries?.map((country) => (
													<option value={country.value}>{country.label}</option>
												))}
											</Field>
										</ListItem>
										{formProps.errors?.country_id && (
											<div className={`ml-auto w-40 text-red-400 md:w-56`}>
												{t(formProps.errors?.country_id)}
											</div>
										)}

										<ListItem
											style={{
												paddingLeft: '0px',
												paddingRight: '0px',
												justifyContent: 'space-between',
											}}
										>
											<ListItemText design={'text-sm'} primary={t('Ciudad')} />
											<Field
												name='call_organizer[0].city'
												type='Text'
												as='select'
												className='body-Text2 h-7 w-40 rounded border-none bg-middle_grey-50 md:w-56'
											>
												<option value=''></option>
												{callOrganizationCities?.length > 1 &&
													callOrganizationCities?.map((city) => (
														<option value={city.label}>{city.label}</option>
													))}
											</Field>
										</ListItem>
										{formProps.errors?.city_id && (
											<div className={`ml-auto w-40 text-red-400 md:w-56`}>
												{t(formProps.errors?.city_id)}
											</div>
										)}

										<FieldTextFormik
											label={t('Código postal')}
											dbName='call_organizer[0].postal_code'
											type='Text'
											designInput={'h-7 !bg-middle_grey-50'}
											errors={
												formProps.errors.call_organizer &&
												formProps.errors.call_organizer[0].postal_code
											}
											errorMessageDesign={'text-red-400 ml-auto text-sm'}
										/>
									</section>

									<section className='mt-6'>
										<TextTitleSub
											title='Documentos Requeridos'
											textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
											style={{
												fontWeight: '700',
											}}
										/>
										<div className='flex w-full flex-col gap-4'>
											<CardLink
												title={'Portafolio'}
												text={
													formProps.values.has_portfolio
														? formProps.values.type === 'FREE'
															? 'Portafolio libre'
															: 'Catálogo prediseñado en Datarte'
														: 'No requerido'
												}
												redirect={`/convocatorias/${slug}/portafolio`}
												designContainer={'!items-start mt-2'}
												titleDesign={'text-purple-50'}
												redirectAction={() => {
													const values = {
														...formProps.values,
														route_slug: slug,
													};
													handleUpdateCallFromForm(values);
												}}
											/>
											<CardLink
												title={'Formulario'}
												text={
													formProps.values.has_form
														? `${
																singleCallForm?.forms?.form_elements?.length ??
																0
														  } campos agregados`
														: 'No requerido'
												}
												redirect={`/convocatorias/${slug}/formulario`}
												designContainer={'!items-start mt-2'}
												titleDesign={'text-purple-50'}
												redirectAction={() => {
													const values = {
														...formProps.values,
														route_slug: slug,
													};
													handleUpdateCallFromForm(values);
												}}
											/>
											<CardLink
												title={'Curriculum vitae'}
												text={
													formProps.values.has_cv
														? 'Requiere adjuntar un Curriculum vitae'
														: 'No requerido'
												}
												redirect={`/convocatorias/${slug}/cv`}
												designContainer={'!items-start mt-2'}
												titleDesign={'text-purple-50'}
												redirectAction={() => {
													const values = {
														...formProps.values,
														route_slug: slug,
													};
													handleUpdateCallFromForm(values);
												}}
											/>
										</div>
									</section>

									<section>
										<TextTitleSub
											title='Términos y Condiciones'
											textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
											style={{
												fontWeight: '700',
											}}
										/>
										<label className='flex w-fit cursor-pointer justify-start gap-2 py-2'>
											{
												//FIXME: Implement Formik Field for the accepts_tcs checkbox
											}

											<input
												type='checkbox'
												name='accepts_tcs'
												checked={formProps.values.accepts_tcs}
												onChange={(e) =>
													formProps.setFieldValue(
														'accepts_tcs',
														e.target.checked
													)
												}
												className={`
									h-4 w-4 appearance-none rounded-sm border-2 
									border-green-50 after:relative 
									after:-top-1/2 after:text-white checked:bg-green-50 checked:after:content-['✓']
								`}
											/>
											<TextContentSecond
												text={
													'He leído y acepto los términos y condiciones de uso.'
												}
											/>
										</label>
										{formProps.errors?.accepts_tcs && (
											<TextContentSecond
												text={t(formProps.errors?.accepts_tcs)}
												design={'text-red-400 ml-auto'}
											/>
										)}
									</section>
								</div>
							</div>
							<section className='mb-16 mt-8 flex w-full flex-wrap items-center justify-center gap-8'>
								{isLoading ? (
									<LoadingText text={t('Cargando')} />
								) : (
									<>
										<ButtonFab
											disabled={isLoading}
											label={'Guardar y salir'}
											size={'large'}
											colour={'white'}
											action={() => formProps.submitForm()}
										/>
										<ButtonFab
											disabled={isLoading}
											label={'Publicar'}
											size={'large'}
											action={() => {
												formProps.setFieldValue('status', 'OPEN');
												formProps.submitForm();
											}}
										/>
									</>
								)}
							</section>
							{showDropzoneMainImageModal && (
								<DropZoneModal
									showDropZoneModal={showDropzoneMainImageModal}
									setShowDropZoneModal={setShowDropzoneMainImageModal}
									setDropZoneFiles={setDropZoneMainFiles}
									dropZoneFiles={dropZoneMainFiles}
									maxFiles={1}
									maxSize={10485760}
								/>
							)}
							{showDropzoneOrganizerImageModal && (
								<DropZoneModal
									showDropZoneModal={showDropzoneOrganizerImageModal}
									setShowDropZoneModal={setShowDropzoneOrganizerImageModal}
									setDropZoneFiles={setDropZoneOrganizerFiles}
									dropZoneFiles={dropZoneOrganizerFiles}
									maxFiles={1}
									maxSize={10485760}
								/>
							)}
						</Form>
					);
				}}
			</Formik>
		</TemplateWithNavBar>
	);
};

export default SingleCallForm;
