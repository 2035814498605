export const FORM_ES = {
	Email: 'Email',
	Contraseña: 'Contraseña',
	Ingresar: 'INGRESAR',
	CheckCuenta: '¿No tienes una cuenta?',
	Regístrate: 'Regístrate',
	EmailRequerido: 'Por favor agrega un email.',
	EmailValidación: 'Por favor agrega un email válido.',
	ContraseñaRequerida: 'Por favor agrega una contraseña.',
	OlvidasteTuContraseña: '¿Olvidaste tu contraseña?',
	IngresaTuEmailYTeAyudaremos: 'Ingresa tu email y te ayudaremos',
	Solicitar: 'Solicitar',
	EligePerfil: 'Elige tu perfil',
	IngresaDatos: 'Ingresa tus datos',
	CrearCuenta: 'Crea una cuenta',
	Artistas: 'Artistas',
	PlanArtistasInformación:
		'Para artistas que buscan organizar sus obras y participar en convocatorias',
	Galerías: 'Galerías',
	PlanGaleríasInformación:
		'Para galerías de arte que desean postular a sus artistas a las convocatorias',
	Continuar: 'Continuar',
	Registrarse: 'Registrarse',
	PrimerNombreRequerido: 'Por favor agrega un nombre.',
	ApellidoRequerido: 'Por favor agrega un apellido.',
	EmailRequeridoRegistro: 'Un email es requerido.',
	ContraseñaRequeridaRegistro: 'Una contraseña es requerida.',
	ContraseñaMínimoCaracteres: 'La contraseña debe tener mínimo 8 caracteres.',
	ContraseñaConfirmación: 'Por favor confirma tu contraseña.',
	ContraseñaNoConcuerda: 'Las contraseñas no coinciden.',
	Nombres: 'Nombres',
	Apellidos: 'Apellidos',
	ConfirmarContraseña: 'Confirmar contraseña',
	Consentimiento: 'Sí, he leído y presto mi consentimiento a los',
	TérminosDeUso: 'Términos de Uso',
	PolíticasSeguridad:
		'del sitio y al procedimiento, al tratamiento y a transferencia de mis datos personales conforme a lo dispuesto en la Política de Privacidad',
	PolíticasSeguridadRequerido:
		'Por favor, acepte los Términos y Condiciones de uso.',
	DirecciónRequerida: 'Por favor agregar una dirección.',
	TeléfonoRequerido: 'Por favor agregar un teléfono.',
	País: 'País',
	Ciudad: 'Ciudad',
	Teléfono: 'Teléfono',
	Dirección: 'Dirección',
	Moneda: 'Moneda',
	Medidas: 'Medidas',
	CreandoCuenta: 'Creando tu cuenta',
	RegresarArtistas: 'Regresar a Artistas',
	NuevoArtista: 'Nuevo Artista',
	Identificación: 'Identificación',
	Nombre: 'Nombre',
	Apellido: 'Apellido',
	Biografía: 'Biografía',
	Educación: 'Educación',
	Exposiciones: 'Exposiciones',
	Publicaciones: 'Publicaciones',
	Guardando: 'Guardando',
	Guardar: 'Guardar',
	ClickCargarImagen: 'Haz clic para cargar la imagen',
	SoltarOClickImagen: 'Suelta o haz click aquí para cargar la imagen.',
	RegresarObras: 'Regresar a Obras',
	NuevaObra: 'Nueva Obra',
	Título: 'Título',
	Código: 'Código',
	OtroCódigo: 'Otro Código',
	Tipo: 'Tipo',
	BiDimensional: 'Bi-dimensional',
	TriDimensional: 'Tri-dimensional',
	Fecha: 'Fecha',
	PaísDeOrigen: 'País de origen',
	Diferencias: 'Diferencias',
	Descripción: 'Descripción',
	Autor: 'Autor',
	Observaciones: 'Observaciones',
	Propietario: 'Propietario',
	Estado: 'Estado',
	Conservado: 'Conservado',
	Vendido: 'Vendido',
	Donado: 'Donado',
	Disponible: 'Disponible',
	Desconocido: 'Desconocido',
	DimensionesObjeto: 'Dimensiones(objeto)',
	AlturaCm: 'Altura (cm)',
	LargoCm: 'Largo (cm)',
	AnchoCm: 'Ancho (cm)',
	PesoKg: 'Peso (kg)',
	DimensionesConBase: 'Dimensiones(con base)',
	Dimensiones: 'Dimensiones',
	TieneBase: 'Tiene base',
	No: 'No',
	SI: 'Si',
	AlturaDeBaseCm: 'Altura de la base (cm)',
	LargoDeBaseCm: 'Largo de la base (cm)',
	AnchoDeBaseCm: 'Ancho de la base (cm)',
	PesoDeBaseKg: 'Peso de la base (kg)',
	TécnicaMateriales: 'Técnica y Materiales',
	Técnica: 'Técnica',
	Soporte: 'Soporte',
	Materiales: 'Materiales',
	Componentes: 'Componentes',
	TieneComponentes: '¿Tiene componentes?',
	NúmeroDeComponentes: 'Número de componentes',
	DetallesDeLosComponentes: 'Detalles de los componentes',
	EstadoDeConservación: 'Estado de conservación',
	Ubicación: 'Ubicación',
	UbicaciónGeneral: 'Ubicación general',
	Cuarto: 'Cuarto',
	UbicaciónNombre: 'Ubicación Nombre',
	Avalúo: 'Avalúo',
	EsconderCatalogaciónAvanzada: 'Esconder catalogación avanzada',
	VerCatalogaciónAvanzada: 'Ver catalogación avanzada',
	RegresarColecciones: 'Regresar a Colecciones',
	NuevaColección: 'Nueva Colección',
	SubColección: 'Subcolección',
	RegresarContactos: 'Regresar a Contactos',
	NuevoContacto: 'Nuevo Contacto',
	Rol: 'Rol',
	Photographer: 'Photographer',
	LocationContact: 'Location Contact',
	Owner: 'Owner',
	NúmeroTeléfono: 'Número de teléfono',
	Otro: 'Otro',
	RegresarDocumentos: 'Regresar a Documentos',
	NuevoDocumento: 'Nuevo Documento',
	Pertenece: 'Pertenece',
	Ninguno: 'Ninguno',
	Artista: 'Artista',
	Obras: 'Obras',
	UnArtista: 'un artista',
	UnaObra: 'una obra',
	RegresarExposiciones: 'Regresar a Exposiciones',
	NuevaExposición: 'Nueva Exposición',
	FechaDeInicio: 'Fecha de inicio',
	FechaFinalización: 'Fecha finalización',
	Lugar: 'Lugar',
	RegresarLugares: 'Regresar a Lugares',
	NuevoLugar: 'Nuevo Lugar',
	Detalles: 'Detalles',
	Contacto: 'Contacto',
	RegresarLugar: 'Regresar a Lugar',
	NuevaSala: 'Nueva Sala',
	Piso: 'Piso',
	Sala: 'Sala',
	RegresarSala: 'Regresar a Sala',
	NuevaUbicación: 'Nueva Ubicación',
	CrearPortafolio: 'Crear Portafolio',
	VisualizarPortafolio: 'Visualizar portafolio',
	PersonalizaTuPortafolio: 'Personaliza tu portafolio',
	Titulo: 'Titulo',
	URL: 'URL',
	Acceso: 'Acceso',
	Público: 'Público',
	PúblicoConClave: 'Público con clave',
	Privado: 'Privado',
	Resolución: 'Resolución',
	Alta: 'Alta',
	Media: 'Media',
	Baja: 'Baja',
	RegresarArtista: 'Regresar a Artista',
	RegresarColección: 'Regresar a Colección',
	RegresarSubcolección: 'Regresar a Subcolección',
	RegresarObra: 'Regresar a Obra',
	RegresarDocumento: 'Regresar a Documento',
	RegresarUbicación: 'Regresar a Ubicación',
	SeleccionarObras: 'Seleccionar obras',
	Seleccionar: 'Seleccionar',
	ObraSeleccionada: 'Obra seleccionada',
	ObrasSeleccionadas: 'Obras seleccionadas',
	ObrasDisponiblesEnDatarte: 'Obras disponibles en Datarte',
	PortafoliosDisponiblesEnDatarte: 'Portafolios disponibles en Datarte',
	DocumentoSeleccionado: 'Documento seleccionado',
	DocumentosSeleccionados: 'Documentos seleccionados',
	RegresarNuevaColección: 'Regresar a nueva colección',
	RegresarNuevaSubColección: 'Regresar a nueva subcolección',
	ArchivosSeleccionados: 'archivos seleccionados',
	Texto: 'Texto',
	Galería: 'Galería',
	AgregarElementos: 'Agregar elementos',
	Imagen: 'Imagen',
	Completo: 'Completo',
	EscogeLaObra: 'Escoge la obra',
	AgregarAPágina: 'Agregar a página',
	NombreDebeSerTexto: 'El nombre debe ser un texto',
	NombreEsNecesario: 'Un nombre es necesario.',
	DebeSerUnNúmero: 'Debe ser un número sin ningún otro carácter.',
	NumeroMáximoCaracteres: 'El número máximo de caracteres es 1000',
	NumeroMáximoCaracteresDosMil: 'El número máximo de caracteres es 2000',
	DimensionDebeSerPositiva: 'La dimensión debe ser un número positivo.',
	DimensionEsNecesaria: 'La dimensión es necesaria.',
	NumeroNoSerMenorCero: 'El número no puede ser menor a 0',
	AvalúoPositivo: 'El avalúo debe ser un número positivo.',
	AvalúoNecesario: 'El avalúo es necesario.',
	RequiereDosCaracteres: 'Requiere por lo menos 2 caracteres.',
	NombreRequerido: 'El nombre es requerido.',
	DetalleEsNecesario: 'Un detalle es necesario.',
	PaísEsNecesario: 'Un país es necesario.',
	CiudadEsNecesario: 'Una ciudad es necesaria.',
	ContactoEsNecesario: 'Un contacto es necesario.',
	PisoNecesario: 'El piso es necesario.',
	ApellidoNecesario: 'Un apellido es necesario.',
	EmailNecesario: 'Un email es necesario.',
	TituloRequerido: 'El Titulo es requerido.',
	LugarRequerido: 'El Lugar es requerido.',
	DescripciónRequerida: 'Requiere una descripción',
	UrlObligatorio: 'El URL es obligatorio.',
	UrlAlfanuméricaYGuiones: 'Únicamente caracteres alfanuméricos y guiones (-).',
	ClaveRequerida: 'Una clave es requerida.',
	ClaveMuyCorta:
		'La clave es muy corta - debe tener por lo menos 8 caracteres.',
	ConfirmarClave: 'Por favor confirma la clave.',
	ClavesNoConcuerdan: 'Las claves no concuerdan.',
	FechaValida: 'Se debe ingresar una fecha válida',
	TeléfonoValido: 'Se requiere un teléfono válido.',
	CorreoValido: 'Se debe ingresar un correo válido.',
	NuevaContraseñaRequerida: 'Una nueva contraseña es requerida.',
	ConfirmarNuevaContraseña: 'Por favor confirma tu nueva contraseña.',
	ContraseñaMuyCorta:
		'La contraseña es muy corta - debe tener por lo menos 8 caracteres.',
	PulsarElTextoDelEjemploParaAgregarloALaPágina:
		'Pulsar el texto del ejemplo para agregarlo a la página',
	AgregarUnTítulo: 'Agregar un título',
	AgregarUnSubtítulo: 'Agregar un subtítulo',
	AñadirUnParrafo: 'Añadir un párrafo',
	AñadirUnPoquitoDeTexto: 'Añadir un poquito de texto',
	UsadoRecientemente: 'Usado recientemente',
	NuevoPortafolio: 'Nuevo portafolio',
	EscogeQueTipoDePortafolioQuieresCrear:
		'Escoge qué tipo de portafolio quieres crear',
	LienzoEnBlanco: 'Lienzo en blanco',
	PortafolioDesdeCero:
		'Puedes crear un portafolio con la información de tu colección y editar todos los detalles',
	PlantillaEditable: 'Plantilla editable',
	PlantillaPredefinida:
		'Puedes escoger una plantilla predefinida agregar tus obras y textos.',
	PortafolioAutomático: 'Portafolio automático',
	PortafolioBásico: 'Portafolio básico',
	PortafolioAutomatizado: 'Portafolio automatizado que no puedes editar.',
	HazClicParaCargarElArchivo: 'Haz clic para cargar el archivo',
	SueltaOHazClickAquíParaCargarElArchivo:
		'Suelta o haz click aquí para cargar el archivo',
	SoltarOClickImagenPortadaColeccion:
		'Suelta o haz clic aquí para cargar la imagen de portada de la colección',
	ClickCargarImagenPortadaColeccion:
		'Haz clic aquí para cargar la imagen de portada de la colección',
	SoltarOClickImagenPortadaObra:
		'Suelta o haz clic aquí para cargar la imagen de la obra',
	ClickCargarImagenPortadaObra:
		'Haz clic aquí para cargar la imagen de portada de la obra',
	SoltarOClickImagenPortadaLugar:
		'Suelta o haz clic aquí para cargar la imagen de portada del lugar',
	ClickCargarImagenPortadaLugar:
		'Haz clic aquí para cargar la imagen de portada del lugar',
	SoltarOClickImagenPortadaContacto:
		'Suelta o haz clic aquí para cargar la imagen de portada del contacto',
	ClickCargarImagenPortadaContacto:
		'Haz clic aquí para cargar la imagen de portada del contacto',
	SoltarOClickImagenPortadaExposicion:
		'Suelta o haz clic aquí para cargar la imagen de portada de la exposición',
	ClickCargarImagenPortadaExposicion:
		'Haz clic aquí para cargar la imagen de portada de la exposición',
	SoltarOClickImagenPortadaArtista:
		'Suelta o haz clic aquí para cargar la imagen de portada del artista',
	ClickCargarImagenPortadaArtista:
		'Haz clic aquí para cargar la imagen de portada del artista',
	CatalogoDeObras: 'Catálogo de obras',
	ParaAplicarAEstaConvocatoriaDebesSeleccionar:
		'Para aplicar a esta convocatoria debes seleccionar tus obras subidas a	Datarte.',
	SeleccionaLasObrasQueQuieres:
		'Selecciona las obras que quieres adjuntar a la convocatoria.',
	ObrasMasDisponibles: 'Obras más disponibles',
	obrasSeleccionadas: 'obras seleccionadas',
	OrdenarPor: 'Ordenar por:',
	SeleccionaUno: '---Selecciona uno---',
	ObrasAprobadas: 'Obras Aprobadas',
	MasNuevoAAntiguo: 'Más nuevo a antiguo',
	MasAntiguoANuevo: 'Más antiguo a nuevo',
	PrecioDeMenorAMayor: 'De menor a mayor',
	PrecioDeMayorAMenor: 'De mayor a menor',

	PortafolioRequerido: 'Portafolio requerido',
	PortafolioLibre: 'Portafolio libre',
	NoTienesPortafoliosEnDatarteParaAplicar:
		'No tienes portafolios en Datarte. Para aplicar por favor crea un Portafolio.',
	ParaAplicarAEstaConvocatoriaDebesSeleccionarUnPortafolio:
		'Para aplicar a esta convocatoria debes seleccionar un portafolio creado en Datarte.',
	SeleccionaElPortafolioQueDeseasAdjuntar:
		'Selecciona el portafolio que deseas adjuntar a la convocatoria.',
	RegresarConvocatoria: 'Regresar a la convocatoria',
	SeleccionarPortafolio: 'Seleccionar portafolio',
	VerPortafolio: 'Ver portafolio',
	RegresarAPostulación: 'Regresar a la postulación',
	RestablecerContraseña: 'Restablecer contraseña',
	NuevaContraseña: 'Nueva contraseña:',
	ConfirmarLaNuevaContraseña: 'Confirmar nueva contraseña:',
	CambiarMiContraseña: 'Cambiar mi contraseña',
	ParaAplicarAEstaConvocatoria:
		'Para poder aplicar a esta convocatoria debes registrarte en Datarte o ingresar a tu cuenta. Una vez lo hagas, puedes postularte en la sección "Convocatoria"',
	IngresaATuCuenta: 'Ingresa a tu cuenta',
	Postularse: 'Postularse',
	CampoRequerido: 'Este campo es requerido',
	EsteEmailEsInvalido: 'Este email es inválido',
	HemosEnviadoLasInstrucciones:
		'Hemos enviado las instrucciones de restablecimiento de contraseña a tu',
	correoElectrónico: 'email.',
	NecesitasAyuda: '¿Necesitas ayuda?',
	YaEnviamosLasInstrucciones:
		'Ya enviamos las instrucciones de restablecimiento de contraseña a tu email.',
	EsteCorreoElectrónicoYaTieneUnaSolicitud:
		'Este email ya tiene una solicitud para el cambio de contraseña, en 24 horas podrás volver a intentarlo.',
	ClickEn: 'Haz clic en:',
	NoSePuedeCambiarLaContraseña:
		'No se puede cambiar la contraseña, ya ha sido restablecida en otra ocasión. Si necesitas repetir este paso, inténtalo de nuevo.',
	CambioDeContraseñaRealizadoCorrectamente:
		'Cambio de contraseña realizado correctamente.',
	SeHaProducidoUnError: 'Se ha producido un error.',
	solicitudCambioDeContraseñaACaducado:
		'Esta solicitud del cambio de contraseña ha caducado. Si necesitas repetir este paso, inténtalo de nuevo.',
	CuentaCreadaExitosamente:
		'Esta cuenta se ha creado exitosamente, ya puedes disfrutar de los beneficios que te ofrece ',
	Cerrar: 'Cerrar',
	CuentaCreada: 'Cuenta creada',
	PostularteEnLasConvocatorias: 'y postularte en las convocatorias.',
	VuelveAIntentarloOSelecciona: 'Vuelve a intentarlo o selecciona ',
	ContraseñaIncorrecta: 'Contraseña incorrecta: ',
	ParaCambiarla: 'para cambiarla',
	Error: 'Error: ',
	DatosDeRegistroIncorrectos: 'Datos de registro incorrectos.',
	SeleccionarImágenPortada: 'Seleccionar imagen de portada',
	ImágenPortada: 'Imagen de portada',
	SeleccionaImagenPortadaColección:
		'Selecciona la imagen de portada para la colección',
	SeleccionarImagenObra: 'Seleccionar imagen de una obra',
	CargarImagenPortada: 'Cargar imagen de portada',
	SeleccionarImagen: 'Seleccionar imagen',
	SeleccionarUnaImagen: 'Seleccionar una imagen',
	CargandoImagen: 'Cargando imagen',
	Empresa: 'Empresa',
	RegresarFormulario: 'Regresar a formulario',
	PortafolioSlugRepetido:
		'Esta URL esta asociada a otro portafolio, por favor usa otra',
	PlantillaExpertos: 'Plantilla expertos',
	PortafolioCreadoPorArtistas:
		'Este portafolio fue creado por artistas para artistas. Es un portafolio editable.',
	RecomendadoPorDatarte: 'Recomendado por Datarte',
	SeleccioneMétodo:
		'Selecciona que método te gusta para comenzar tu portafolio',
	InformaciónDeObra:
		'Escoge la información de la obra que quieres incluir en el portafolio.',
	Crear: 'Crear',
	AñoInvalido: 'Año invalido',
	Precios: 'Precios',
	FormularioNoEnviado:
		'No fue posible enviar el formulario, por favor intenta de nuevo.',
	ConfiguraciónDelPortafolio: 'Configuración del portafolio',
	LosCambiosSeGuardanAutomáticamente:
		'Los cambios realizados se guardaran automáticamente.',
	AccesoRequerido: 'El tipo de acceso es requerido',
	NoFuePosibleCargarElPortfolioExperto:
		'No fue posible cargar el portafolio experto.',
	Cargando: 'Cargando',
	Organizaciones: 'Organizaciones',
	EscogeLosCamposDeObras:
		'Escoge los campos de obras que quieras mostrar en tu portafolio',
	DatosDeObras: 'Datos de obras',

	TuCuentaSeHaCreadoExitosamente: 'Tu cuenta se ha creado exitosamente.',
	OrganizaciónTuCuentaSeHaCreadoExitosamente:
		'Tu cuenta se ha creado exitosamente. Ya puedes comenzar a utilizar la app Datarte y a gestionar tus convocatorias.',
	GaleríaTuCuentaSeHaCreadoExitosamente:
		'Tu cuenta se ha creado exitosamente. Ya puedes comenzar a utilizar la app Datarte y postular artistas  en las convocatorias.',

	PlanesModalTitulo:
		'Para postularte a las convocatorias en Datarte elige un plan',
	PlanesModalTexto: 'Disfruta de los beneficios que te ofrece Datarte',
	PlanesModalBoton: 'Ver planes',

	HemosCreadoUnPortafolio:
		'Hemos creado un portafolio a partir de tu colección.',
	ParaEditarTuPortafolio:
		'Para editar tu portafolio, haz click en los diferentes elementos.  Recuerda: los cambios se guardaran automáticamente.',
	NoHayColecciones:
		'Para crear un nuevo portafolio necesitas tener una colección creada.',
	CrearUnaColecciónAquí: 'Crea una colección aquí.',
	AutomáticamenteGenerado: 'Automáticamente generado',
	CreamosEstaColecciónGeneral:
		'Creamos esta colección con todas las obras vinculadas a esta cuenta. Actualiza automáticamente los cambios que realices.',
	CreamosEstePortafolioGeneral:
		'Creamos este portafolio con los datos que tiene la colección completa. Actualiza automáticamente los cambios que realices.',
	PlanOrganizaciónInformación:
		'Para residencias, museos, galerías de arte, centros culturales y otros que desean crear y promocionar sus convocatorias',

	DebeSeleccionarUnaOpción: 'Debe seleccionar una opción',
	GaleríasDeArte: 'Galerías de arte',
	SeleccionaUnaOpción: 'Selecciona una opción',
	Codigo: 'Código',
	OtroCodigo: 'Otro código',

	NoHayResultados:
		'No hay resultados que coincidan con tus criterios de búsqueda',
	tamañoMinimoPermitido: 'El tamaño mínimo permitido es de  1 MB.',
	NotaTamañoSoportado:
		'Nota: el tamaño máximo soportado de la imagen es de 10MB y el tamaño mínimo recomendado es de  1MB.',
	URLVideo: 'URL Video',
	UnURLEsNecesario: 'Un URL es necesario',
	Duración: 'Duración',
	AutorVideo: 'Autor video',
	Aspecto: 'Aspecto',
	NúmeroDeSerie: 'Número de serie',
	ArchivosPermitidosVideo: 'Archivos permitidos: Youtube, Vimeo, Dailymotion.',
	ArtistaNoPuedeEliminarse: 'No es posible eliminar este artista',
	ArtistaNoPuedeEliminarseDescription:
		'Este artista esta postulado a una convocatoria, por lo tanto, no se puede eliminar',
	ColecciónNoPuedeEliminarse: 'No es posible eliminar esta colección',
	ColecciónNoPuedeEliminarseDescription:
		'Esta colección forma parte de una postulación, por lo tanto, no se puede eliminar',
	ObraNoPuedeEliminarse: 'No es posible eliminar esta obra',
	ObraNoPuedeEliminarseDescription:
		'Esta obra forma parte de una postulación, por lo tanto, no se puede eliminar',
	PortafolioNoPuedeEliminarse: 'No es posible eliminar este portafolio',
	PortafolioNoPuedeEliminarseDescription:
		'Este portafolio forma parte de una postulación, por lo tanto, no se puede eliminar',
	ExpertPorfolioSlideNoPuedeEliminarse: 'No es posible eliminar esta sección',
	ExpertPorfolioSlideNoPuedeEliminarseDescripción:
		'Un portafolio experto no puede quedar sin secciones, debe contener al menos una',
	URLInvalida: 'URL invalida',

	FechaCierreRequerida: 'Fecha de cierre requerida',
};
